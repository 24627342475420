
App.angular.controller('DealersController', function($scope, $timeout, $resource, ngTableParams) {

    var Api = $resource('/admin/ajax/users');

    $scope.export = function () {
        var filters = $scope.tableParams.parameters().filter;
        window.open('/admin/dealers/export?params=' + btoa(JSON.stringify(filters)));
    };

    $scope.onDomReady = function(tableParams) {

        tableParams.role = 'dealer';

        $scope.tableParams = new ngTableParams(
            tableParams,
            {
                total: 0,
                getData: function($defer, params) {
                    $scope.loading = true;
                    Api.get(params.url(), function(data) {
                        $timeout(function() {
                            $scope.loading = false;
                            params.total(data.total);
                            $defer.resolve(data.result);
                        }, 500);
                    });
                }
            }
        );
    };

    $scope.editEnable = function() {

        if(!angular.isDefined($scope.enableEdit)) {

            $scope.enableEdit = false;
        }

        if ($scope.enableEdit === false) {

            swal({
                    title: "Inserisci il PIN",
                    type: "input",
                    showCancelButton: true,
                    closeOnCancel: true,
                    closeOnConfirm: false
                },
                function (isConfirm) {

                    if (isConfirm) {

                        if (isConfirm === App.pin) {

                            swal("", "Il PIN è corretto.", "success");

                            $scope.enableEdit = true;

                            window.location.href ='/admin/dealers/create';



                        } else {

                            swal("", "Il PIN non è corretto.", "error");

                        }
                    }

                }
            )
        }
    };

    // exportList = function() {
    //
    //     window.open('/admin/dealers/export');
    //
    // }
});