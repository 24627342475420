
App.angular

    .controller('FirmwareController', function($scope) {

        console.log($scope);

        $scope.enableEdit = false;

        $scope.onDomReady = function (type) {

            if (type === 'create') {

                $scope.enableEdit = true;
                $scope.$apply();

            }
        };

        $scope.remove = function(firmwareId) {

            swal({
                title: "Sei sicuro di voler cancellare questo firmware?",
                text: "Questa azione non è reversibile!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Si, cancella firmware!",
                cancelButtonText: "Annulla",
                closeOnConfirm: false
            }, function() {

                $.ajax({
                    type: "DELETE",
                    url: '/admin/ajax/firmwares/' + firmwareId,
                    success: function() {

                        window.location = '/admin/firmwares';
                    }
                });
            });
        };

        $scope.copyFirmware = function(firmwareId) {

            swal({
                title: "Sei sicuro di voler copiare questo firmware?",
                text: "Sarà creato un nuovo firmware con gli stessi dati ma senza codice di revisione",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Si, copia firmware!",
                cancelButtonText: "Annulla",
                closeOnConfirm: false
            }, function() {


                $.ajax({
                    type: "POST",
                    url: '/admin/ajax/firmwares/' + firmwareId + '/copy',
                    success: function(data) {

                        swal({
                            title:"",
                            text:"Firmware copiato",
                            type:"success"
                        }, function() {

                            window.location = '/admin/firmwares/' + data.id + '/edit';
                        });
                    }
                });

            });
        };

        $scope.editEnable = function() {

            if ($scope.enableEdit === false) {

                swal({
                        title: "Inserisci il PIN",
                        type: "input",
                        showCancelButton: true,
                        closeOnCancel: true,
                        closeOnConfirm: false
                    },
                    function (isConfirm) {

                        if (isConfirm) {

                            if (isConfirm === App.pin) {

                                swal("", "Il PIN è corretto.", "success");

                                $scope.enableEdit = true;

                            } else {

                                swal("", "Il PIN non è corretto.", "error");

                            }
                        }

                        if ($scope.enableEdit === true) {

                            document.getElementById('editButton').click();
                        }

                    }
                )
            }
        };

    })
    .controller('FirmwareFileUploadController', function($scope, $http, $filter, $window) {

        $scope.options = {
            url: fileUploadUrl
        };

        $scope.loadingFiles = true;

        $http.get(fileUploadUrl)
            .then(
                function (response) {
                    $scope.loadingFiles = false;
                    $scope.queue = response.data.files || [];
                },
                function () {
                    $scope.loadingFiles = false;
                }
            );

        $scope.getNumberOfFiles = function() {

            var files = $scope.queue;

            var total = 0;

            $.each(files, function(index, file){
                if (typeof file.deleteUrl !== 'undefined')
                    total++;
            });

            return total;
        };
    })
    .controller('FileDestroyController', function($scope, $http) {

        var file = $scope.file,
            state;

        if (file.url) {

            file.$state = function () {
                return state;
            };

            file.$destroy = function () {
                state = 'pending';
                return $http({
                    url: file.deleteUrl,
                    method: file.deleteType
                }).then(
                    function () {
                        state = 'resolved';
                        $scope.clear(file);
                    },
                    function () {
                        state = 'rejected';
                    }
                );
            };
        } else if (!file.$cancel && !file._index) {

            file.$cancel = function () {
                $scope.clear(file);
            };
        }
    });
