
App.angular.controller('NewsController', function($scope, $timeout, $resource, ngTableParams) {

    var Api = $resource('ajax/news');

    $scope.onDomReady = function(tableParams) {

        // Initializes ng-table
        $scope.tableParams = new ngTableParams(
            tableParams,
            {
                total: 0,
                getData: function($defer, params) {
                    $scope.loading = true;
                    Api.get(params.url(), function(data) {
                        $timeout(function() {
                            $scope.loading = false;
                            params.total(data.total);
                            $defer.resolve(data.result);
                        }, 500);
                    });
                }
            }
        );
    };

    $scope.deleteNews = function(newsId) {
        swal({
            title: "Sei sicuro di voler cancellare questa news?",
            text: "Questa azione non è reversibile!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Si, cancella news!",
            cancelButtonText: "Annulla",
            closeOnConfirm: false
        }, function() {

            $.ajax({
                type: "DELETE",
                url: '/admin/ajax/news/destroy/' + newsId,
                success: function() {

                    window.location.reload();
                }
            });
        });
    };
});