var App = {

    angular: angular.module('adminApp', ['ngResource', 'ngTable', 'blueimp.fileupload']),

    pin : '',

    init: function() {

        this.initValidation();
        this.initAlerts();
        this.initForms();
        this.initSelects();
        this.initDateTimePickers();
        this.initInputMasks();
    },

    initValidation: function() {

        $('form.form-validate').validate({

            highlight: function(element) {
                $(element).closest('.form-group').removeClass('has-success').addClass('has-error');
            },
            unhighlight: function(element) {
                $(element).closest('.form-group').removeClass('has-error').addClass('has-success');

                $(element).popover('destroy');
            },
            errorPlacement: function(error, element) {

                if (typeof $(element).data('bs.popover') == 'undefined' || $(element).data('bs.popover').getContent() != $(error).text()) {

                    $(element).popover({
                        content: $(error).text(),
                        placement: 'bottom'
                    });

                    $(element).popover('show');
                }
            },
            events: 'submit',
            selector: 'input[type!=submit], select, textarea',
            callback: function( elem, valid ) {
                if ( ! valid ) {
                    $( elem ).addClass('error');
                }
            }
        });
    },

    initAlerts: function() {

        $(".alert.alert-success").fadeTo(2500, 500).slideUp(500, function(){
            $(".alert.alert-success").alert('close');
        });

        $("#flash-overlay-modal").modal();
    },

    initForms: function() {

        $('form.dirty').areYouSure( {'silent':true} );
        $(window).on('beforeunload', function() {
            if ($('form.dirty').hasClass('dirty')) {
                return "Se lasci questa pagina le tue modifiche andranno perse!";
            }
        });
    },

    initDateTimePickers: function() {

        $('input.input-date').each(function() {

            var data = $(this).data();

            $(this).datetimepicker({
                lang: 'en',
                timepicker: data.timePicker,
                format: data.timePicker ? 'd/m/Y H:i' : 'd/m/Y',
                scrollInput: false
            });
        });
    },

    initSelects: function() {

        $('select.select2').each(function() {

            var data = $(this).data();

            $(this).select2({
                placeholder : data.placeholder || '',
                allowClear: data.allowClear || false
            });

        });
    },

    initInputMasks: function() {
        $("input[data-inputmask]").inputmask();
    }
};
