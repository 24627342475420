
App.angular

    .controller('ParameterController', function($scope) {

        $scope.remove = function(parameterId) {

            swal({
                title: "Sei sicuro di voler cancellare questo parametro?",
                text: "Questa azione non è reversibile!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Si, cancella parametro!",
                cancelButtonText: "Annulla",
                closeOnConfirm: false
            }, function() {

                $.ajax({
                    type: "DELETE",
                    url: '/admin/ajax/parameters/destroy/' + parameterId,
                    success: function() {

                        window.location = '/admin/parameters';
                    }
                });
            });
        };
    });