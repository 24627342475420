
App.angular.controller('UsersController', function($scope, $timeout, $resource, ngTableParams) {

    var Api = $resource('ajax/users');

    $scope.onDomReady = function(tableParams) {

        tableParams.role = 'admin';

        // Initializes ng-table
        $scope.tableParams = new ngTableParams(
            tableParams,
            {
                total: 0,
                getData: function($defer, params) {
                    $scope.loading = true;
                    Api.get(params.url(), function(data) {
                        $timeout(function() {
                            $scope.loading = false;
                            params.total(data.total);
                            $defer.resolve(data.result);
                        }, 500);
                    });
                }
            }
        );
    };
});