
App.angular.controller('BikesController', function($scope, $timeout, $resource, ngTableParams) {

    var Api = $resource('/admin/ajax/bikes');

    $scope.export = function () {
        var filters = $scope.tableParams.parameters().filter;
        window.open('/admin/bikes/export?params=' + btoa(JSON.stringify(filters)));
    };


    $scope.create = function () {

        window.open('/admin/bikes/create');

        $scope.enableEdit = true;

        document.click();

    };


    $scope.onDomReady = function(tableParams) {

        // $scope.enableEdit = false;

        // Initializes ng-table
        $scope.tableParams = new ngTableParams(
            tableParams,
            {
                total: 0,
                getData: function($defer, params) {
                    $scope.loading = true;
                    Api.get(params.url(), function(data) {
                        $timeout(function() {
                            $scope.loading = false;
                            params.total(data.total);
                            $defer.resolve(data.result);
                        }, 500);
                    });
                }
            }
        );
    };

    $scope.editEnable = function() {

        if(!angular.isDefined($scope.enableEdit)) {

            $scope.enableEdit = false;
        }

        if ($scope.enableEdit === false) {

            swal({
                    title: "Inserisci il PIN",
                    type: "input",
                    showCancelButton: true,
                    closeOnCancel: true,
                    closeOnConfirm: false
                },
                function (isConfirm) {

                    if (isConfirm) {

                        if (isConfirm === App.pin) {

                            swal("", "Il PIN è corretto.", "success");

                            $scope.enableEdit = true;

                            window.location.href ='/admin/bikes/create';



                        } else {

                            swal("", "Il PIN non è corretto.", "error");

                        }
                    }

                }
            )
        }
    };

});