
App.angular

    .controller('UserController', function($scope, $http, $filter, $window) {

        $scope.remove = function(userId) {

            swal({
                title: "Sei sicuro di voler cancellare questo utente?",
                text: "Questa azione non è reversibile!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Si, cancella utente!",
                cancelButtonText: "Annulla",
                closeOnConfirm: false
            }, function() {

                $.ajax({
                    type: "DELETE",
                    url: '/admin/ajax/users/destroy/' + userId,
                    success: function() {

                        window.location = '/admin/users';
                    }
                });
            });
        };

    });