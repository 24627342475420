
App.angular

    .controller('BikeController', function($scope, $http, $filter, $resource, $timeout, $window, ngTableParams) {

        $scope.enableEdit = false;

        $scope.onDomReady = function (tableParams, bikeId, type) {

            if (type === 'create') {

                $scope.enableEdit = true;
                $scope.$apply();

            }

            $scope.api = $resource('/admin/ajax/bikes/' + bikeId + '/logs');

            $scope.exportLog = function (bikeId) {
                var filters = $scope.tableParams.parameters().filter;
                window.open('/admin/bikes/' + bikeId + '/export/logs?params=' + btoa(JSON.stringify(filters)));
            };

            $scope.tableParams = new ngTableParams(
                tableParams,
                {
                    total: 0,
                    getData: function ($defer, params) {
                        $scope.loading = true;
                        $scope.api.get(params.url(), function (data) {
                            $timeout(function () {
                                $scope.loading = false;
                                params.total(data.total);
                                $defer.resolve(data.result);
                            }, 500);
                        });
                    }
                }
            );
        };

        $scope.editEnable = function() {
            if ($scope.enableEdit === false) {

                swal({
                        title: "Inserisci il PIN",
                        type: "input",
                        showCancelButton: true,
                        closeOnCancel: true,
                        closeOnConfirm: false
                    },
                    function (isConfirm) {

                        if (isConfirm) {

                            if (isConfirm === App.pin) {

                                swal("", "Il PIN è corretto.", "success");

                                $scope.enableEdit = true;

                            } else {

                                swal("", "Il PIN non è corretto.", "error");

                            }
                        }

                        if ($scope.enableEdit === true) {

                            document.getElementById('editButton').click();
                        }

                    }
                )
            }
        };

        $scope.remove = function(bikeId) {

            swal({
                title: "Sei sicuro di voler cancellare questa moto?",
                text: "Questa azione non è reversibile!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Si, cancella moto!",
                cancelButtonText: "Annulla",
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            }, function() {

                $.ajax({
                    type: "DELETE",
                    url: '/admin/ajax/bikes/destroy/' + bikeId,
                    success: function() {

                        window.location = '/admin/bikes';
                    }
                });
            });
        };

        $scope.removeLogs = function(bikeId) {

            swal({
                title: "Sei sicuro di voler cancellare i logs di questa moto?",
                text: "Questa azione non è reversibile!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Si, cancella logs!",
                cancelButtonText: "Annulla",
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            }, function() {

                $.ajax({
                    type: "DELETE",
                    url: '/admin/ajax/bikes/' + bikeId + '/logs/destroy',
                    success: function() {

                        window.location = '/admin/bikes/' + bikeId + '/edit';
                    }
                });
            });
        };
    });