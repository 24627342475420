/**
 * Created by lorenzosanti on 04/12/15.
 */

App.angular.controller('SingleNewsController', function($scope) {

    $scope.remove = function(newsId) {

        swal({
            title: "Sei sicuro di voler cancellare questa news?",
            text: "Questa azione non è reversibile!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Si, cancella news!",
            cancelButtonText: "Annulla",
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
        }, function() {

            $.ajax({
                type: "DELETE",
                url: '/admin/ajax/news/destroy/' + newsId,
                success: function() {

                    window.location = '/admin/news';
                }
            });
        });
    };

    $scope.publish = function(newsId) {
        swal({
            title: "Sei sicuro di voler pubblicare questa news?",
            text: "Confermando, la news sarà pubblicata agli utenti",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Si, pubblica news!",
            cancelButtonText: "Annulla",
            closeOnConfirm: false
        }, function() {

            $.ajax({
                type: "PUT",
                url: '/admin/ajax/news/publish/' + newsId,
                success: function() {

                    window.location.reload();
                }
            });
        });
    };

    $scope.unpublish = function(newsId) {
        swal({
            title: "Sei sicuro di voler depubblicare questa news?",
            text: "Confermando, la news sarà depubblicata per gli utenti",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Si, depubblica news!",
            cancelButtonText: "Annulla",
            closeOnConfirm: false
        }, function() {

            $.ajax({
                type: "PUT",
                url: '/admin/ajax/news/unpublish/' + newsId,
                success: function() {

                    window.location.reload();
                }
            });
        });
    };
});