
App.angular

    .controller('DealerController', function($scope, $http, $filter, $window) {

        $scope.enableEdit = false;

        $scope.onDomReady = function (type) {

            if (type === 'create') {

                $scope.enableEdit = true;
                $scope.$apply();

            }
        };

        $scope.remove = function(userId) {

            swal({
                title: "Sei sicuro di voler cancellare questo dealer?",
                text: "Questa azione non è reversibile!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Si, cancella dealer!",
                cancelButtonText: "Annulla",
                closeOnConfirm: false
            }, function() {



                $.ajax({
                    type: "DELETE",
                    url: '/admin/ajax/dealers/destroy/' + userId,
                    success: function() {

                        window.location = '/admin/dealers';
                    }
                });
            });
        };

        $scope.editEnable = function() {

            if(!angular.isDefined($scope.enableEdit)) {

                $scope.enableEdit = false;
            }

            if ($scope.enableEdit === false) {

                swal({
                        title: "Inserisci il PIN",
                        type: "input",
                        showCancelButton: true,
                        closeOnCancel: true,
                        closeOnConfirm: false
                    },
                    function (isConfirm) {

                        if (isConfirm) {

                            if (isConfirm === App.pin) {

                                swal("", "Il PIN è corretto.", "success");

                                $scope.enableEdit = true;

                            } else {

                                swal("", "Il PIN non è corretto.", "error");

                            }
                        }

                        if ($scope.enableEdit === true) {

                            document.getElementById('editButton').click();
                        }

                    }
                )
            }
        };

    })