
App.angular.controller('SoftwareVersionsController', function($scope, $timeout, $resource, ngTableParams) {

    var Api = $resource('/admin/ajax/software-versions');


    $scope.create = function () {

        window.open('/admin/software-versions/create');

        $scope.enableEdit = true;

        document.click();

    };


    $scope.onDomReady = function(tableParams) {

        // $scope.enableEdit = false;

        // Initializes ng-table
        $scope.tableParams = new ngTableParams(
            tableParams,
            {
                total: 0,
                getData: function($defer, params) {
                    $scope.loading = true;
                    Api.get(params.url(), function(data) {
                        $timeout(function() {
                            $scope.loading = false;
                            params.total(data.total);
                            $defer.resolve(data.result);
                        }, 500);
                    });
                }
            }
        );
    };

    $scope.remove = function(swVersionId) {

        swal({
            title: "Sei sicuro di voler cancellare questa versione software?",
            text: "Questa azione non è reversibile!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Si, cancella versione software!",
            cancelButtonText: "Annulla",
            closeOnConfirm: false
        }, function() {

            $.ajax({
                type: "DELETE",
                url: '/admin/ajax/software-versions/destroy/' + swVersionId,
                success: function() {

                    window.location = '/admin/software-versions';
                }
            });
        });
    };

    $scope.editEnable = function(action, modelId) {

        if(!angular.isDefined($scope.enableEdit)) {

            $scope.enableEdit = false;
        }

        if ($scope.enableEdit === false) {

            swal({
                    title: "Inserisci il PIN",
                    type: "input",
                    showCancelButton: true,
                    closeOnCancel: true,
                    closeOnConfirm: false
                },
                function (isConfirm) {

                    if (isConfirm) {

                        if (isConfirm === App.pin) {

                            swal("", "Il PIN è corretto.", "success");

                            $scope.enableEdit = true;

                            if (action == 'create') {

                                window.location.href ='/admin/software-versions/create';
                            }
                            else if (action == 'delete') {

                                $scope.remove(modelId);
                            }


                        } else {

                            swal("", "Il PIN non è corretto.", "error");

                        }
                    }

                }
            )
        }
        else if (action == 'create') {

            window.location.href ='/admin/software-versions/create';
        }
        else if (action == 'delete') {

            $scope.remove(modelId);
        }
    };

});